import { yupResolver } from '@hookform/resolvers/yup';
import { pointOnFeature } from '@turf/turf';
import axios, { type AxiosError } from 'axios';
import type { FC } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { KeyedMutator } from 'swr';
import useSWRMutation from 'swr/mutation';
import * as yup from 'yup';
import { toast } from '@volvo/vce-uikit';
import { StyledInput } from '../../../../../components/common/input-field/styles';
import { useSiteContext } from '../../../../../context/site';
import { StyledButton, StyledForm } from './styles';

const url = import.meta.env.VITE_API_ENDPOINT_SITESIM + '/site-machine/simulation';

const schema = yup
  .object({
    amount: yup.number().min(0).max(100).notRequired().optional().nullable(),
    amountOfGuests: yup.number().min(0).max(100).notRequired().optional().nullable(),
  })
  .test('', 'One of the fields is required', (value) =>
    Boolean(value.amount || value.amountOfGuests),
  );

type Schema = yup.InferType<typeof schema>;

type Body = {
  amount?: number;
  amountOfGuests?: number;
  center: number[];
  siteId: string;
};

type Props = {
  refetch: KeyedMutator<string>;
};

export const Create: FC<Props> = ({ refetch }) => {
  const { t } = useTranslation();
  const { siteBoundary, siteId } = useSiteContext();
  const {
    handleSubmit,
    reset,
    formState: { isValid },
    register,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { amount: 5, amountOfGuests: 0 },
  });

  const sendRequest = async (url: string, { arg }: { arg: Schema }) => {
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    if (!siteBoundary) {
      toast.error('Site boundary is not available');
      return;
    }

    const body: Body = {
      amount: arg.amount ?? undefined,
      amountOfGuests: arg.amountOfGuests ?? undefined,
      center: pointOnFeature(siteBoundary).geometry.coordinates,
      siteId,
    };

    await axios
      .post(url, body, header)
      .then((res) => {
        toast.success(res.data);
        refetch();
        reset();
      })
      .catch((error: AxiosError) => {
        toast.error(error.response?.statusText);
      });
  };

  const { trigger, isMutating } = useSWRMutation(url, sendRequest);

  const onSubmit: SubmitHandler<Schema> = (data) => trigger(data);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="amount">{t('Number of machines (0 - 100)')}</label>
      <StyledInput
        id="amount"
        {...register('amount')}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <label htmlFor="amountOfGuests">{t('Number of guests (0 - 100)')}</label>
      <StyledInput
        id="amountOfGuests"
        {...register('amountOfGuests')}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <StyledButton type="submit" disabled={!isValid || isMutating}>
        {t('Simulate')}
      </StyledButton>
    </StyledForm>
  );
};
