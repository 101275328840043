import { Suspense, type FC } from 'react';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import { Deviations } from '../../../federations/deviations/Deviations';
import { useApp } from '../../page-layout/PageLayout';

export const DeviationsPage: FC = () => {
  const { siteId } = useApp();

  return (
    <>
      <Suspense fallback={<CenteredSpinner />} />
      <Deviations siteId={siteId} companyId="278c2b55-21dd-4128-832e-687b06800821" />
    </>
  );
};
