import styled from '@emotion/styled';
import { Button } from '@volvo/vce-uikit';

export const StyledForm = styled.form`
  display: grid;
  grid-area: create;
  gap: 16px;
  grid-template-columns: 1fr 150px;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  width: 150px;
`;
