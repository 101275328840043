import styled from '@emotion/styled';
import { StatusLight } from '@volvo/vce-uikit';

export const Grid = styled.section`
  max-width: 400px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 150px;
  grid-template-areas:
    'status delete'
    'create create';
`;

export const StyledStatusLight = styled(StatusLight)`
  grid-area: status;
`;
