import { ThemeProvider } from '@emotion/react';
import { Suspense, type FC } from 'react';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import { ProductionData } from '../../../federations/production-data';
import { theme } from '../../../theme';
import { useApp } from '../../page-layout/PageLayout';

export const ProcessPage: FC = () => {
  const { siteId } = useApp();

  return (
    <>
      <Suspense fallback={<CenteredSpinner />} />
      <ThemeProvider theme={theme}>
        <ProductionData siteId={siteId} />
      </ThemeProvider>
    </>
  );
};
