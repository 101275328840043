import { type FC } from 'react';
import { SiteMap } from '../../../components';
import { useApp } from '../../page-layout/PageLayout';

type Props = { baseUrl: string };

export const SiteMapPage: FC<Props> = ({ baseUrl }) => {
  const { siteId } = useApp();

  return <SiteMap siteId={siteId} baseUrl={baseUrl} />;
};
