import axios from 'axios';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { StatusLight, StatusLightStatus } from '@volvo/vce-uikit';
import { Create } from './create/Create';
import { Delete } from './delete/Delete';
import { Grid } from './styles';

const url = import.meta.env.VITE_API_ENDPOINT_SITESIM + '/site-machine-simulation';

const fetchWithToken = (url: string, token: string) =>
  axios
    .get(url, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);

export const SimulateMachines: FC = () => {
  const { t } = useTranslation();

  const { data, error, isLoading, mutate } = useSWR(
    [url, `Bearer ${localStorage.getItem('token')}`],
    ([url, token]) => fetchWithToken(url, token),
  );

  if (isLoading) return <StatusLight status={StatusLightStatus.Info}>{t('loading')}</StatusLight>;

  if (error) return <StatusLight status={StatusLightStatus.Error}>{error.message}</StatusLight>;

  return (
    <Grid>
      <StatusLight status={data.at(0) > 0 ? StatusLightStatus.Success : StatusLightStatus.Neutral}>
        {data}
      </StatusLight>
      <Delete refetch={mutate} numberOfSimulations={Number.parseInt(data.at(0))} />
      <Create refetch={mutate} />
    </Grid>
  );
};
