import { ApolloProvider } from '@apollo/client';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, useAuth } from '@volvo/vce-uikit';
import { SiteProvider } from '../../../context/site';
import { getApolloClient } from '../../../gql/apollo-client';
import { useApp } from '../../page-layout/PageLayout';
import { SimulateMachines } from './simulate-machines/SimulateMachines';
import { Grid } from './styles';

export const AdminPage: FC = () => {
  const { t } = useTranslation();
  const { siteId } = useApp();
  const { getToken } = useAuth();
  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  return (
    <ApolloProvider client={client}>
      <Grid>
        <Tabs>
          <Tab key="simulate-machines" icon="forward-collision-warning" active>
            {t('Simulate machines')}
          </Tab>
        </Tabs>
        <SiteProvider siteId={siteId}>
          <SimulateMachines />
        </SiteProvider>
      </Grid>
    </ApolloProvider>
  );
};
